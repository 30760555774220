import {
    CONFIRM_BOOKING,
    CONFIRM_BOOKING_SUCCESS,
    CONFIRM_BOOKING_FAILED,
    CLEAR_BOOKING
} from "../store/types";
import { RequestPushMsg } from '../other/NotificationFunctions';
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { formatBookingObject } from '../other/sharedFunctions';
import { get, onValue, push, set, update } from "firebase/database";
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";

export const clearBooking = () => (dispatch) => {
    dispatch({
        type: CLEAR_BOOKING,
        payload: null,
    });
}

export const addBooking = (bookingData, imageBlob, imageType = 'taxis_chein') => async (dispatch) => {
  const { bookingRef, settingsRef, singleUserRef, bookingImageRef, singleBookingRef } = firebase;

  try { 
    dispatch({
      type: CONFIRM_BOOKING,
      payload: bookingData,
    });
 
    const settingsdata = await get(settingsRef);
    const settings = settingsdata.val();
 
    let data = await formatBookingObject(bookingData, settings);
 
    const res = await push(bookingRef, data);
    const bookingKey = res.key;
 
    data.id = bookingKey;
 
    if (bookingData.requestedDrivers) {
      const drivers = bookingData.requestedDrivers;
      Object.keys(drivers).map((uid) => {
        onValue(
          singleUserRef(uid),
          (snapshot) => {
            if (snapshot.val()) {
              const pushToken = snapshot.val().pushToken;
              const ios = snapshot.val().userPlatform === "IOS";
              if (pushToken) {
                RequestPushMsg(pushToken, {
                  title: store.getState().languagedata.defaultLanguage.notification_title,
                  msg: store.getState().languagedata.defaultLanguage.new_booking_notification,
                  screen: 'DriverTrips',
                });
              }
            }
          },
          { onlyOnce: true }
        );
        return drivers[uid];
      });
    }
 
    if (imageBlob) {
      const uploadTask = uploadBytesResumable(bookingImageRef(bookingKey, imageType), imageBlob);

      await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error("Error during file upload:", error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(bookingImageRef(bookingKey, imageType));

            if (imageType === "taxis_chein") {
              data.taxis_chein = downloadURL;
            }

            resolve();
          }
        );
      });
  
      update(singleBookingRef(bookingKey), data)
    } else {
      console.warn("No imageBlob provided. Skipping file upload.");
    }
 
    dispatch({
      type: CONFIRM_BOOKING_SUCCESS,
      payload: {
        booking_id: bookingKey,
        mainData: {
          ...data,
        },
      },
    });
  } catch (error) {
    console.error("Error during booking creation:", error);

    dispatch({
      type: CONFIRM_BOOKING_FAILED,
      payload: `${error.code}: ${error.message}`,
    });
  }
};
  
