import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";

const DocumentUploader = ({ onFileBlob, documentBlob, setDocumentUrl }) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const blobUrl = URL.createObjectURL(file);
    setDocumentUrl(blobUrl)
    if (!file) {
      alert(t("no_doc"));
      return;
    }

    if (file.size > 3 * 1024 * 1024) {
      alert(t("max_upload_limit"));
      return;
    }

    try {
      setUploading(true);
      const fileBlob = new Blob([file], { type: file.type });
      onFileBlob(fileBlob);
      alert(t("doc_upload_success"));
    } catch (error) {
      alert(t("failed_upload_doc"));
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: 1,
        backgroundColor: "#f9f9f9",
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t("upload_taxischein")}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {t("supported_formats")}
      </Typography>
      <label htmlFor="file-upload">
        <StyledButton
          component="span"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          {t("upload_taxischein")}
        </StyledButton>
        <input
          id="file-upload"
          type="file"
          accept="*/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </label>
      {uploading && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <CircularProgress />
          <Typography variant="body2" sx={{ mt: 1 }}>
            {t("uploading")}
          </Typography>
        </Box>
      )}
      {documentBlob && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {t("confirm_txt_taxichein")}
        </Alert>
      )}
    </Box>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default DocumentUploader;
