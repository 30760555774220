import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Tooltip,
} from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useTranslation } from "react-i18next";

const DocumentViewer = ({ documentUrl }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const openDocument = () => {
    if (!documentUrl) {
      alert(t("no_doc_available"));
      return;
    }

    setLoading(true);

    const newWindow = window.open(documentUrl, "_blank");

    if (!newWindow || newWindow.closed || typeof newWindow.closed === "undefined") {
      alert(t("popup_error"));
    }

    setLoading(false);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setLoading(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
        borderRadius: 3,
        backgroundColor: "white",
        mx: "auto",
        my: "2px"
      }}
      key={"taxis_chein"}
    >
      {documentUrl ? (
        <>
          <ArticleOutlinedIcon
            sx={{
              fontSize: 48,
              color: "#4caf50",
              mb: 2,
            }}
          />
          <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
            {t("view_doc")}
          </Typography>
          <Tooltip title={t("open_doc_tab")} arrow>
            <Button
              variant="contained"
              size="large"
              startIcon={<ArticleOutlinedIcon />}
              onClick={openDocument}
              sx={{
                backgroundColor: "#4caf50",
                "&:hover": {
                  backgroundColor: "#388e3c",
                },
                px: 3,
              }}
              disabled={loading}
            >
              {loading ? (
                <>
                  <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                  {t("open_doc")}
                </>
              ) : t("open_doc_taxis")}
            </Button>
          </Tooltip>
        </>
      ) : (
        <Alert
          severity="warning"
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {t("no_doc_available")}
        </Alert>
      )}
    </Box>
  );
};

export default DocumentViewer;
